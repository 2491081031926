<template>
  <tr class="c-signee">
    <td>
      <div class="display-flex align-items-center">
        <div class="c-signee__image">
          <img
            :src="
              player.image
                ? player.image + '?width=36'
                : this.$root.dark_mode
                ? 'https://s3.amazonaws.com/assets.sidearmsports.com/common/images/card-default_dark.jpg'
                : 'https://s3.amazonaws.com/assets.sidearmsports.com/common/images/card-default_light.jpg'
            "
            alt=""
          />
        </div>
        <div class="c-signee__name font-size-18 line-height-125">
          <a
            v-bind:href="'/roster.aspx?rp_id=' + player.id"
            class="c-card__link"
            >{{ player.fullname }}</a
          >
        </div>
      </div>
    </td>
    <td class="c-signee__table--position">
      {{ player.position_short.toUpperCase() }}
    </td>
    <td class="font-size-16 line-height-125">
      {{ player.rp_academic_year }}
    </td>
    <td class="font-size-16 line-height-125">
      {{ player.player_hometown }}
    </td>
    <td>
      <a
        class="
          c-signee__button
          s-common__button s-common__button-primary
          display-flex
          align-items-center
        "
        v-bind:href="'/roster.aspx?rp_id=' + player.id"
        ><feather
          class="display-none bp-768:display-block margin-r-12"
          size="16px"
          type="user"
        ></feather>
        <span class="display-none margin-r-4 bp-768:display-inline">Full</span>
        Bio</a
      >
    </td>
  </tr>
</template>

<script>
export default {
  name: "SigneeRow",
  props: {
    player: Object,
  },
  data: () => ({}),
  methods: {},
  computed: {},
};
</script>

<style scoped lang="scss">
.c-signee {
  &__button {
    width: 112px;
    text-align: center;
    margin: 0 auto;
    justify-content: center;
  }
  td {
    padding: 12px;
    box-shadow: inset 0px -1px 0px #f1f1f1;
    background: $main-color;
    text-align: center;
    color: $main-font-color;
    &:first-child {
      text-align: left;
      font-weight: 700;
      a {
        color: $main-font-color;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  &__image {
    width: 36px;
    height: 36px;
    flex-shrink: 0;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 24px;
  }
  &__moreinfo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin: 0 auto;
    font-weight: 700;
    text-transform: uppercase;
    width: 69px;
    height: 35px;
    background: var(--color-primary);
    color: var(--text-on-primary);
    text-decoration: none;
    transition: 0.25s;
  }
  @media screen and (max-width: 1023px) {
    &__button {
      width: 60px;
    }
    &__table thead {
      th:not(:first-child):not(:last-child) {
        display: none;
      }
    }
    td:not(:first-child):not(:last-child) {
      display: none;
    }
    td:last-child {
      .c-signee__button {
        margin-right: 0;
      }
    }
  }
}
</style>
