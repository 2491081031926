/* eslint-disable */
export const slugify = (string) => {
    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
    const p = new RegExp(a.split('').join('|'), 'g');
    return string.toString().toLowerCase()
        .replace(/\s+/g, '-')
        .replace(p, c => b.charAt(a.indexOf(c)))
        .replace(/&/g, '-and-')
        .replace(/[^\w\-]+/g, '')
        .replace(/\-\-+/g, '-')
        .replace(/^-+/, '')
        .replace(/-+$/, '');
}
/* eslint-enable */

export const get_player_from_roster_by_slug = (roster, player_slug) => {
  return roster.find((player) => player.slug === player_slug);
};

export const sort_by = (prop) => {
  return (a, b) => (a[prop] < b[prop] ? -1 : a[prop] > b[prop] ? 1 : 0);
};

export const get_players_from_roster_by_count = (count, roster) => {
  return roster.slice(0, count);
};

export const value_by_stringed_key = (object, string_key_path) => {
  string_key_path = string_key_path.replace(/\[(\w+)\]/g, ".$1");
  string_key_path = string_key_path.replace(/^\./, "");
  var pieces = string_key_path.split(".");

  for (let i = 0, n = pieces.length; i < n; ++i) {
    var k = pieces[i];
    if (k in object) {
      object = object[k];
    } else {
      return;
    }
  }

  return object;
};

export const create_url_params_from_object = (params_obj) => {
  let param_string = "";

  Object.keys(params_obj).forEach((key, index) => {
    param_string += index === 0 ? "?" : "&";

    param_string += `${key}=${encodeURIComponent(params_obj[key])}`;
  });

  return param_string;
};

export const generate_components = (components) => {
  console.log(components);
  return components;
};
